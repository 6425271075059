import React from 'react';
import StarRatings from 'react-star-ratings';
import utils from '@happylife-a/utils';
import webCore from '@happylife-a/web-core';
import { Card, CardBody, Box, Flex, Text } from '@chakra-ui/react';
import { theme } from '../../../theme';
import { LocalizedLink } from '../../LocalizedLink';
import S3Image from '../../S3Image';
import BuyNow from './BuyNow';

export default function MessageProductCard({
  product,
  productDetail,
  setIsOpenChatTab,
  setIsOpenMessageTab,
}) {
  const { locale } = webCore.contexts.useTranslation();
  const { price, discountedPrice } = webCore.hooks.useIncreasedPrices({
    productDetail: productDetail,
  });

  const slug = product?.slug || product?.id;

  return (
    <Card
      w={{ base: '100%', md: 300 }}
      height="full"
      position="relative"
      boxShadow="none"
      as={LocalizedLink}
      href={`/products/${slug}`}
    >
      <CardBody
        p={0}
        transition="border .25s"
        borderColor="grey.300"
        borderWidth={1}
        borderRadius="10px"
      >
        <Box w="100%" position="relative">
          <S3Image
            folder={S3Image.folders.FOLDER_PRODUCTS}
            imageKey={productDetail?.images?.[0]?.imageKey || ''}
            size={'201x172'}
            alt={productDetail?.title?.[locale] || product?.title?.[locale]}
            borderRadius="10px 10px 0 0"
            objectFit="cover"
            w="100%"
            h={44}
          />
        </Box>
        <Box pt={4} px={2}>
          <Box h={4} w="full">
            <Text variant={{ base: 'body-reg-lg', md: 'sub-head-reg' }}>
              {utils.helpers.string.truncate(
                productDetail?.title?.[locale] || product?.title?.[locale],
                25
              )}
            </Text>
          </Box>
          <Flex justifyContent="space-between" alignItems="center">
            <Flex flexDirection="column">
              <Flex>
                <Text
                  variant={{
                    base: 'body-reg-sm-bold',
                    sm: 'sub-head-bold',
                    md: 'h3-bold',
                  }}
                  mt={2}
                >
                  {discountedPrice || price}
                  {/* @TODO: show original price with line-through if product was discounted */}
                </Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Flex>
                  <StarRatings
                    rating={product?.reviewAverage}
                    numberOfStars={5}
                    starRatedColor={theme.colors.orange[500]}
                    starWidthAndHeight="13px"
                    starDimension="16px"
                    starSpacing="2px"
                    starEmptyColor="white"
                    className="star-ratings"
                  />
                  <Text variant="body-reg-sm" mt={1} ml={1}>
                    ({product?.reviewCount})
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <BuyNow
              detailId={productDetail?.id}
              setIsOpenMessageTab={setIsOpenMessageTab}
              setIsOpenChatTab={setIsOpenChatTab}
            />
          </Flex>
        </Box>
      </CardBody>
    </Card>
  );
}
