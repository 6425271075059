import { useCallback, useEffect, useState } from 'react';
import utils from '@happylife-a/utils';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useShoppingCarts as useShoppingCartsContext } from '../../contexts/ShoppingCartContext';
import { useUser } from '../../contexts/UserContext';
import { reduceCartsTotal } from '../../helpers';
import {
  REACT_QUERY_GET_CART_ITEMS_IDS_QUERY_KEY,
  REACT_QUERY_GET_CART_ITEMS_QUERY_KEY,
  REACT_QUERY_CART_ITEMS_BULK_QUERY_KEY,
  REACT_QUERY_CART_CALCULATE_QUERY_KEY,
  REACT_QUERY_DELIVERY_OPTIONS_QUERY_KEY,
} from '../constants';
import shoppingCartUseCase from '../factories/shoppingCart';
import { useIncreasedPrices } from '../../hooks';

const useShoppingCartUseCase = () => {
  const { isUnknownUser } = useUser();
  return shoppingCartUseCase(isUnknownUser);
};

export const useGetCartItemsIds = (options = {}) => {
  const useCase = useShoppingCartUseCase();

  const query = useQuery(
    [REACT_QUERY_GET_CART_ITEMS_IDS_QUERY_KEY],
    useCase.getCartItemsIds,
    options
  );

  return {
    ...query,
    data: query.data || {},
  };
};

export const useGetCartItems = (options = {}) => {
  const useCase = useShoppingCartUseCase();
  const { increase } = useIncreasedPrices();

  const query = useQuery(
    [REACT_QUERY_GET_CART_ITEMS_QUERY_KEY],
    useCase.getCartItems,
    options
  );

  const { setCartItemsTotal } = useShoppingCartsContext();
  useEffect(() => {
    if (query.data) {
      setCartItemsTotal(reduceCartsTotal(query.data.shoppingCarts, increase));
    }
  }, [query?.data, setCartItemsTotal, increase]);

  return {
    ...query,
    data: query.data || {},
  };
};

export const useCreateCart = () => {
  const useCase = useShoppingCartUseCase();
  const mutation = useMutation(useCase.createCart);

  return mutation;
};

export const useUpdateCartCount = () => {
  const useCase = useShoppingCartUseCase();
  const mutation = useMutation(useCase.updateCartCount);

  return mutation;
};

export const useDeleteCart = () => {
  const useCase = useShoppingCartUseCase();
  const mutation = useMutation(useCase.deleteCart);

  return mutation;
};

export const useBulkDeleteCartItems = (options) => {
  const useCase = useShoppingCartUseCase();
  const queryClient = useQueryClient();

  return useMutation((ids) => {
    queryClient.removeQueries([REACT_QUERY_CART_ITEMS_BULK_QUERY_KEY]);

    return useCase.bulkDeleteCartItems(ids);
  }, options);
};

export const useShoppingCarts = (isInCart, { setIsCostCalculating } = {}) => {
  const { user, setOptimisticUpdate } = useUser();
  const queryClient = useQueryClient();
  const { setCartItemsTotal } = useShoppingCartsContext();
  const { increase } = useIncreasedPrices();

  const { mutate: deleteCart } = useDeleteCart();
  const { mutate: createCart } = useCreateCart();
  const { mutate: updateCart } = useUpdateCartCount();
  const { mutate: bulkDeleteCart } = useBulkDeleteCartItems();

  const queryKeyCartItems = [REACT_QUERY_GET_CART_ITEMS_QUERY_KEY];
  const updateCartItemsData = useCallback(
    (callback) => {
      queryClient.setQueriesData(queryKeyCartItems, (data) => {
        const newData = callback({ ...data });
        setCartItemsTotal(reduceCartsTotal(newData.shoppingCarts, increase));
        return newData;
      });
    },
    [setCartItemsTotal, increase]
  );

  const queryKeyCartIds = [REACT_QUERY_GET_CART_ITEMS_IDS_QUERY_KEY];
  const updateCartIdsData = useCallback((callback) => {
    queryClient.setQueriesData(queryKeyCartIds, (data) => {
      const newData = callback({ ...data });
      newData.total = newData.ids.reduce((acc, item) => acc + item.quantity, 0);

      setCarts(newData);
      return newData;
    });
  });

  const cartsCachedData = queryClient.getQueryData(queryKeyCartIds);
  const [carts, setCarts] = useState(cartsCachedData);

  useEffect(() => {
    setCarts(cartsCachedData);
  }, [cartsCachedData]);

  const toggleCart = useCallback(
    (productDetailId, { onCreate, onDelete, count } = {}) => {
      if (isInCart(productDetailId)) {
        const onDeleteCartItemSuccess = () => {
          updateCartIdsData((data) => ({
            ...data,
            ids: data.ids.filter(
              (id) =>
                !utils.helpers.id.same(id.productDetailId, productDetailId)
            ),
          }));

          let lastObjectInfo = null;
          updateCartItemsData((data) => ({
            ...data,
            meta: { ...data.meta, total: data.meta.total - 1 },
            shoppingCarts: data.shoppingCarts?.filter((item) => {
              const isRemoved = utils.helpers.id.same(
                item.productDetailId,
                productDetailId
              );
              if (isRemoved) {
                lastObjectInfo = item;
              }
              return !isRemoved;
            }),
          }));

          if (typeof onDelete === 'function') {
            onDelete(lastObjectInfo);
          }
        };

        deleteCart(
          { productDetailId: productDetailId },
          { onSuccess: onDeleteCartItemSuccess }
        );
      } else {
        const createCartInput = {
          productDetailId: productDetailId,
          quantity: count || 1,
        };

        const onCreateSuccess = (response) => {
          const lastObjectInfo = {
            id: response.id,
            productId: response.productId,
            productDetailId: response.productDetailId,
            quantity: response.quantity,
            productDetail: {
              price: increase(response.productDetail.price),
              discountedPrice: increase(response.productDetail.discountedPrice),
            },
          };

          updateCartIdsData((data) => ({
            ...data,
            ids: [...data.ids, lastObjectInfo],
          }));
          updateCartItemsData((data) => ({
            ...data,
            meta: { ...data.meta, total: data.meta.total + 1 },
            shoppingCarts: [response, ...(data?.shoppingCarts || [])],
          }));

          if (typeof onCreate === 'function') {
            onCreate(lastObjectInfo);
          }
        };

        createCart(createCartInput, { onSuccess: onCreateSuccess });
      }
    },
    [isInCart, deleteCart, createCart, increase]
  );

  const updateCartCount = useCallback(
    (cart, { onSuccessUpdate } = {}) => {
      const input = {
        id: cart.productDetailId,
        quantity: cart.quantity,
        updatedAt: new Date().toISOString(),
      };

      const setUpdateStatus = (isUpdating) => {
        if (typeof setIsCostCalculating === 'function') {
          setIsCostCalculating(isUpdating);
        }
        if (typeof onSuccessUpdate === 'function') {
          onSuccessUpdate(isUpdating);
        }
      };

      const onUpdateSuccess = (response) => {
        updateCartItemsData((data) => ({
          ...data,
          shoppingCarts: data.shoppingCarts.map((item) => {
            if (utils.helpers.id.same(item.id, response.id)) {
              item.quantity = cart.quantity;
            }
            return item;
          }),
        }));
        updateCartIdsData((data) => ({
          ...data,
          ids: data.ids.map((item) => {
            if (
              utils.helpers.id.same(
                item.productDetailId,
                response.productDetailId
              )
            ) {
              item.quantity = response.quantity;
            }
            return item;
          }),
        }));

        setUpdateStatus(false);
      };

      setUpdateStatus(true);
      updateCart(input, { onSuccess: onUpdateSuccess });
    },
    [updateCart, setIsCostCalculating]
  );

  const bulkDeleteCarts = useCallback(
    (productDetailIds, { onSuccessDelete, onErrorDelete }) => {
      const onBulkDeleteCartItemsSuccess = () => {
        queryClient.setQueriesData(
          [REACT_QUERY_GET_CART_ITEMS_IDS_QUERY_KEY],
          (data) => {
            const newData = { ...data };

            const filteredCartData = {
              ...newData,
              ids: newData.ids.filter(
                (item) => !productDetailIds.includes(item.productDetailId)
              ),
            };
            return filteredCartData;
          }
        );

        let lastObjectInfo = null;
        updateCartItemsData((data) => ({
          meta: { ...data.meta, total: data.meta.total - 1 },
          shoppingCarts: data.shoppingCarts.filter((item) => {
            const isRemoved = productDetailIds.includes(item.productDetailId);
            if (isRemoved) {
              lastObjectInfo = item;
            }
            return !isRemoved;
          }),
        }));

        if (typeof onSuccessDelete === 'function' && lastObjectInfo) {
          onSuccessDelete(lastObjectInfo);
        }
      };
      const onErrorBulkDelete = (e) => {
        if (typeof onErrorDelete === 'function') {
          onErrorDelete(e.message);
        }
      };
      bulkDeleteCart(
        { productDetailIds: productDetailIds },
        {
          onSuccess: onBulkDeleteCartItemsSuccess,
          onError: onErrorBulkDelete,
        }
      );
    },
    [bulkDeleteCart]
  );

  useEffect(() => {
    if (!carts) {
      return;
    }

    const shoppingCartsCount = (carts.ids || []).reduce(
      (acc, cart) => acc + cart.quantity,
      0
    );

    setOptimisticUpdate({
      ...user,
      customerInfo: {
        ...user.customerInfo,
        shoppingCartsCount: shoppingCartsCount,
      },
    });
  }, [carts]);

  return {
    toggleCart: toggleCart,
    updateCartCount: updateCartCount,
    bulkDeleteCarts: bulkDeleteCarts,
  };
};

export const useCalculateCostAndDate = (params, options = {}) => {
  const useCase = useShoppingCartUseCase();

  return useQuery(
    [REACT_QUERY_CART_CALCULATE_QUERY_KEY, params],
    () => useCase.calculateCostAndDate(params),
    options
  );
};

export const useGetDeliveryOptions = (params, options = {}) => {
  const useCase = useShoppingCartUseCase();

  return useQuery(
    [REACT_QUERY_DELIVERY_OPTIONS_QUERY_KEY, params],
    () => useCase.getDeliveryOptions(params),
    options
  );
};
